
import { PropType, reactive, ref, defineComponent, onMounted, } from "vue";
import { CalendarOutlined, SmileOutlined } from "@ant-design/icons-vue";
import { ExaminerRoleEnum, GetGlobalExaminerByIdInput, GlobalExaminerByIdDto, ModifyGlobalExaminerInput } from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, message } from "ant-design-vue";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const useForm = Form.useForm;

export default defineComponent({
    props: {
        examinerIdList: {
            type: Array as PropType<string[]>,
            required: true,
        },
        isBatchEdit: {
            type: Boolean,
            required: true,
        },
        onClose: {
            type: Function,
            required: true,
        },
    },
    components: {
        CalendarOutlined,
        SmileOutlined,
    },
    setup(props) {

        const examinerIdList = props.examinerIdList;
        const isBatchEdit = props.isBatchEdit;
        const saveLoading = ref(false);
        const spinning = ref(false);
        const title = ref<string>("Edit Examiner Task");
        const globalExaminerForm = reactive(new GlobalExaminerByIdDto());
        const close = (isRefresh: Boolean) => props.onClose(isRefresh);
        const checkRole = ref(true);
        const leftEffectiveDate = ref<Dayjs>();

        // 处理关闭标签的事件
        const handleClose = (
            tagToRemove: { examinerId?: string | undefined; examinerName?: string | undefined },
            index: number
        ) => {
            // 从数组中移除标签
            globalExaminerForm.examinerList?.splice(index, 1);
        };

        const getGlobalExaminerById = () => {
            spinning.value = true;
            _Client.examinerClient.getGlobalExaminerById(new GetGlobalExaminerByIdInput({ examinerIdList: examinerIdList })).then(rep => {
                globalExaminerForm.init(rep);
                if (globalExaminerForm.leftEffectiveDate) {
                    leftEffectiveDate.value = dayjs(globalExaminerForm.leftEffectiveDate);
                }
            }).finally(() => { spinning.value = false; })
        }

        const modifyGlobalExaminer = () => {
            spinning.value = true;
            saveLoading.value = true;
            _Client.examinerClient.modifyGlobalExaminer(new ModifyGlobalExaminerInput({
                examinerIdList: globalExaminerForm.examinerList?.map(x => x.examinerId),
                examinerRole: checkRole.value ? ExaminerRoleEnum.ICS : undefined,
                leftEffectiveDate: leftEffectiveDate.value ? new Date(leftEffectiveDate.value.format("YYYY-MM-DD")) : undefined,
                remark: globalExaminerForm.remark,
                isBatchEdit: isBatchEdit,
            })).then(() => {
                message.success("Save Successfully");
                close(true);
            }).catch(() => {
                message.error("Save Failed")
            }).finally(() => {
                spinning.value = false;
                saveLoading.value = false;
            })
        }

        onMounted(() => {
            getGlobalExaminerById();
        })

        return {
            close,
            handleClose,
            modifyGlobalExaminer,
            title,
            dayjs,
            saveLoading,
            spinning,
            globalExaminerForm,
            checkRole,
            leftEffectiveDate,
            isBatchEdit,
        };
    },
});
