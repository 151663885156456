
import { _Client } from '@/api-client';
import { defineComponent, onMounted, ref } from 'vue'
import { GetGlobalExaminerLogsInput, PageResultOfListOfGetGlobalExaminerLogsDto } from '@/api-client/client';
import dayjs from 'dayjs';
import Pagination from '@/components/Pagination/index.vue';

const columns = [
  {
    title: 'Action',
    dataIndex: 'action',
  },
  {
    title: 'User',
    dataIndex: 'operator'
  },
  {
    title: 'Time',
    dataIndex: 'time'
  },
  {
    title: 'Message',
    dataIndex: 'content'
  }
]


export default defineComponent({
  components: {
    Pagination
  },
  props: {
    show: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const show = () => props.show(false);

    const loading = ref(false)
    const data = ref(new PageResultOfListOfGetGlobalExaminerLogsDto())
    const input = ref(new GetGlobalExaminerLogsInput())
    input.value.pageIndex = 1
    input.value.pageSize = 10

    const getList = () => {
      loading.value = true

      _Client.examinerClient.getGlobalExaminerLogs(input.value).then(rep => {
        data.value = rep
      }).finally(() => {
        loading.value = false
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      loading,
      input,
      data,
      columns,
      dayjs,
      getList,
      show
    }
  }
})
