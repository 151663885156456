
import { PropType, reactive, ref, defineComponent, onMounted } from "vue";
import { CalendarOutlined, SmileOutlined } from "@ant-design/icons-vue";
import {
  ExaminerRoleEnum,
  GetGlobalExaminerByIdInput,
  GlobalExaminerByIdDto,
  AddGlobalExaminerInput,
} from "@/api-client/client";
import { _Client } from "@/api-client";
import { Form, message } from "ant-design-vue";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

const useForm = Form.useForm;

export default defineComponent({
  props: {
    onClose: {
      type: Function,
      required: true,
    },
  },
  components: {
    CalendarOutlined,
    SmileOutlined,
  },
  setup(props) {
    const footer = ref(true);
    const title = ref("");
    const messageStr = ref("");

    const showMessageModal = ref(false);
    const saveLoading = ref(false);
    const spinning = ref(false);
    const addglobalExaminerForm = reactive(new AddGlobalExaminerInput());
    const checkRole = ref(true);
    const leftEffectiveDate = ref<Dayjs>();

    const close = (isRefresh: Boolean) => {
      if (addglobalExaminerForm.examinerIdStr?.length > 0 && isRefresh==false) {
        footer.value = true;
        showMessageModal.value = true;
        title.value = "Changes Unsaved";
        messageStr.value =
          "Any changes will not be saved, are you sure you want to close this window?";
      } else {
        props.onClose(isRefresh);
      }
    };

    const addGlobalExaminer = () => {
      spinning.value = true;
      saveLoading.value = true;
      (addglobalExaminerForm.examinerRole = checkRole.value
        ? ExaminerRoleEnum.ICS
        : undefined),
        _Client.examinerClient
          .addGlobalExaminer(addglobalExaminerForm)
          .then((res) => {
            if (res == "") {
              message.success("Save Successfully");
              close(true);
            } else {
              footer.value = false;
              showMessageModal.value = true;
              title.value = "Check Examiner";
              messageStr.value = res;
            }
          })
          .catch((ex) => {
            message.error("Save Failed");
          })
          .finally(() => {
            spinning.value = false;
            saveLoading.value = false;
          });
    };

    const MessageYes = () => {
      showMessageModal.value = false;
      props.onClose(false);
    };

    return {
      close,
      addGlobalExaminer,
      MessageYes,
      footer,
      dayjs,
      saveLoading,
      spinning,
      checkRole,
      leftEffectiveDate,
      addglobalExaminerForm,
      showMessageModal,
      title,
      messageStr,
    };
  },
});
