
import { _Client } from '@/api-client';
import { SearchGlobalExaminerInput, GlobalExaminerDto, PageResultOfListOfGlobalExaminerDto } from '@/api-client/client';
import { defineComponent, onMounted, reactive, ref } from "vue";
import Pagination from '@/components/Pagination/index.vue';
import EditGlobalExaminer from './editGlobalExaminer.vue';
import AddGlobalExaminer from './addGlobalExaminer.vue';
import DisableGlobalExaminer from './disableGlobalExaminer.vue';
import log from './log.vue';

export default defineComponent({
  components: {
    Pagination,
    EditGlobalExaminer,
    AddGlobalExaminer,
    DisableGlobalExaminer,
    log
  },

  setup() {
    const columns = [
      {
        title: 'Examiner Name',
        dataIndex: 'examinerName',
        key: 'examinerName',
        width: 120
      },
      {
        title: 'Examiner No.',
        dataIndex: 'examinerNo',
        key: 'examinerNo',
        width: 80
      },
      {
        title: 'Email Address',
        dataIndex: 'emailAddress',
        key: 'emailAddress',
        width: 130
      },
      {
        title: 'Role',
        dataIndex: 'examinerRole',
        key: 'examinerRole',
        width: 50
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 50
      }
    ]

    const showEditModal = ref(false);
    const showDisableModal = ref(false);
    const showAddModal = ref(false);
    const tableLoading = ref<boolean>(false);
    const dataSource = reactive<PageResultOfListOfGlobalExaminerDto>(new PageResultOfListOfGlobalExaminerDto());
    const cardLoading = ref<boolean>(false);
    const selectedRowKeys = ref<string[]>([]); //// checkbox 已选中的数据
    const isBatchEdit = ref(false);
    const showLogModal = ref(false)
    const searchParames = reactive<SearchGlobalExaminerInput>(new SearchGlobalExaminerInput({
      pageIndex: 1,
      pageSize: 10,
      examinerNo: undefined,
      examinerFirstName: undefined,
      examinerLastName: undefined
    }));

    const search = () => {
      searchParames.pageIndex = 1;
      getList();
    }

    const resetSearchParams = () => {
      searchParames.init(new SearchGlobalExaminerInput({
        pageIndex: 1,
        pageSize: 10,
        examinerNo: undefined,
        examinerFirstName: undefined,
        examinerLastName: undefined
      }));

      getList();
    }

    // 单个编辑Global考官
    const editGlobalExaminer = (examinerId: string) => {
      isBatchEdit.value = false;
      selectedRowKeys.value = [examinerId];
      showEditModal.value = true;
    }

    // 批量编辑Global考官
    const batchDisableGlobalExaminer = () => {
      showDisableModal.value = true;
    }

        // 批量编辑Global考官
    const batchEditGlobalExaminer = () => {
      isBatchEdit.value = true;
      showEditModal.value = true;
    }

    // 批量编辑Global考官
    const addGlobalExaminer = () => {
      showAddModal.value = true;
    }

    // 关闭Disable弹窗
    const closeDisableGlobalExaminer = (isRefresh: boolean) => {
      selectedRowKeys.value = [];
      showDisableModal.value = false;
      if (isRefresh) {
        getList();
      }
    }

    // 关闭编辑弹窗
    const closeEditGlobalExaminer = (isRefresh: boolean) => {
      selectedRowKeys.value = [];
      showEditModal.value = false;
      if (isRefresh) {
        getList();
      }
    }

    // 关闭添加弹窗
    const closeAddGlobalExaminer = (isRefresh: boolean) => {
      showAddModal.value = false;
      if (isRefresh) {
        getList();
      }
    }

    //// 复选框筛选
    const onSelectChange = (
      keys: string[],
      selectedRows: GlobalExaminerDto[]
    ) => {
      selectedRowKeys.value = keys;
    };

    const getList = () => {
      tableLoading.value = true;
      _Client.examinerClient.getGlobalExaminerList(searchParames).then(rep => {
        dataSource.cnt = rep.cnt;
        dataSource.ret = rep.ret;
      }).finally(() => { tableLoading.value = false; selectedRowKeys.value = []; })
    }

    const showLog = (isShow: boolean) => {
      showLogModal.value = isShow;
    }

    onMounted(() => {
      getList();
    })

    return {
      cardLoading,
      columns,
      tableLoading,
      searchParames,
      selectedRowKeys,
      dataSource,
      showEditModal,
      isBatchEdit,
      showLogModal,
      showAddModal,
      showDisableModal,
      batchDisableGlobalExaminer,
      closeDisableGlobalExaminer,
      showLog,
      resetSearchParams,
      onSelectChange,
      getList,
      closeEditGlobalExaminer,
      batchEditGlobalExaminer,
      editGlobalExaminer,
      search,
      closeAddGlobalExaminer,
      addGlobalExaminer,
    }
  }
})
